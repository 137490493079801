<!--
 * @Auth: linjituan
 * @Date: 2021-12-01 15:10:43
 * @LastEditors: linjituan
 * @LastEditTime: 2021-12-01 15:59:46
-->

<template>
  <div>
    <div v-if="currentSelectedId">
      <div v-for="(item, index) in list" :key="index" class="vehicle-event-item">
        <span v-if="showEventType">{{ eventTypeFormat(item) }}</span>
        <span>{{ parseDateTime(new Date(item.happenTime), 'yyyy-MM-dd HH:mm:ss:SSS') }}</span>
        <span><a href="javascript:;;" @click="toHistoryTrack(item)">详情</a></span>
      </div>
      <div class="vehicle-event-btn" @click="toVehicleEvent()">
        <a href="javascript:;;">查询更多</a>
      </div>
    </div>
    <div v-else class="vehicle-event-btn">请先选择车辆</div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    showEventType: {
      type: Boolean,
      default: false
    },
    currentSelectedId: {
      type: String,
      default: ''
    },
    activeKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      eventOptions: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.getDicts('vehicle_event_type').then((response) => {
        this.eventOptions = response.data
      })
    },
    toHistoryTrack(item) {
      this.$emit('toHistoryTrack', item)
    },
    eventTypeFormat(row, column) {
      return this.selectDictLabel(this.eventOptions, row.type)
    },
    toVehicleEvent() {
      if (this.activeKey === '4') {
        this.$router.push('/monitor/suddenStopLog?vehicleId=' + this.currentSelectedId)
      } else {
        this.$router.push('/monitor/vehicleImportantEvents?vehicleId=' + this.currentSelectedId)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.vehicle-event-item {
  display: flex;
  span {
    margin-right: 10px;
    flex: 2;
  }
  span:first-child {
    flex: 1;
  }
  span:last-child {
    flex: 0 0 40px;
  }
  margin-bottom: 5px;
}

.vehicle-event-btn {
  text-align: center;
}
</style>
